/*------- 14. Brand logo style  -------*/

.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
        height: auto; /* Or set a specific height if needed */
      }
    }
  }

  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;

    /* Set unique width and height */
    img {
      transition: all 2ms ease 0s;
      width: 120px; /* Set a fixed width */
      height: 80px; /* Set a fixed height */
    }

    &:hover img {
      filter: grayscale(0%);
    }
  }
}

.brand-logo-wrap {
  padding: 100px 90px;
  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}
